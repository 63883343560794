import $ from './jquery-3.4.1.min.js';

function initPage() {
  $(".navbar-burger").click(function() {
    // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
    $(".navbar-burger").toggleClass("is-active");
    $(".navbar-menu").toggleClass("is-active");
  });
}

function decorateDocPage() {
    $('.doc h1').addClass( 'title is-3  is-spaced' );
    $('.doc h2').addClass( 'subtitle is-4  is-spaced' );
    $('.doc h3').addClass( 'subtitle is-5  is-spaced' );

    $('.doc img').addClass( "image-medium" );
    $('.doc img').on('click', function(e) {
        console.log("image clicked")
        if($(this).hasClass("image-medium")){
            $(this).removeClass("image-medium");
            $(this).addClass("image-large");
        }else if($(this).hasClass("image-large")){
            $(this).removeClass("image-large");
            $(this).addClass("image-small");
        }else if($(this).hasClass("image-small")){
            $(this).removeClass("image-small");
            $(this).addClass("image-medium");
        }
        console.log($(this).attr("class"));
    });
}

export var toggle = function ( button1, button2, fulltext) {
  console.log("TOGGLE GOMA!");
  $(button1).toggle(0);
  $(button2).toggle(0);  
  $(fulltext).slideToggle('slow');
}

export var prepare_script = function (){
  $(document).ready( function(){
    initPage();
  });
  console.log("JS for layout is prepared.");
}

export var prepare_script_doc = function (){
  $(document).ready( function(){
    initPage();
    decorateDocPage();
  });
  console.log("JS for layout_doc is prepared.");
}

